import React from "react"

function Filter({ location, setLocation, school, setSchool }) {
    const handleLocation = e => setLocation(e.target.value)
    const handleSchool = e => setSchool(e.target.value)
    return (
        <section className="filter">
            <form aria-label="selected option will filter results">
                <div role="group" aria-labelledby="filter_location" className="filter__group">
                    <h2 id="filter_location">Location</h2>
                    <hr />
                    <input id="all_locations" type="radio" name="location" value="all" onChange={handleLocation} checked={location === "all"} />
                    <label htmlFor="all_locations">All locations</label>
                    <input id="scad_atlanta" type="radio" name="location" value="atlanta" onChange={handleLocation} checked={location === "atlanta"} />
                    <label htmlFor="scad_atlanta">SCAD Atlanta</label>
                    <input id="scad_savannah" type="radio" name="location" value="savannah" onChange={handleLocation} checked={location === "savannah"} />
                    <label htmlFor="scad_savannah">SCAD Savannah</label>
                </div>
                <div role="group" aria-labelledby="filter_school" className="filter__group">
                    <h2 id="filter_school">School</h2>
                    <hr />
                    <input id="all_schools" type="radio" name="school" value="all schools" onChange={handleSchool} checked={school === "all schools"} />
                    <label htmlFor="all_schools">All schools</label>
                    <input id="building_arts" type="radio" name="school" value="school of building arts" onChange={handleSchool} checked={school === "school of building arts"} />
                    <label htmlFor="building_arts">School of Building Arts</label>
                    <input id="communication_arts" type="radio" name="school" value="school of communication arts" onChange={handleSchool} checked={school === "school of communication arts"} />
                    <label htmlFor="communication_arts">School of Communication Arts</label>
                    <input id="design" type="radio" name="school" value="school of design" onChange={handleSchool} checked={school === "school of design"} />
                    <label htmlFor="design">School of Design</label>
                    <input id="fashion" type="radio" name="school" value="school of fashion" onChange={handleSchool} checked={school === "school of fashion"} />
                    <label htmlFor="fashion">School of Fashion</label>
                    <input id="digital_media" type="radio" name="school" value="school of digital media" onChange={handleSchool} checked={school === "school of digital media"} />
                    <label htmlFor="digital_media">School of Digital Media</label>
                    <input id="entertainment_arts" type="radio" name="school" value="school of entertainment arts" onChange={handleSchool} checked={school === "school of entertainment arts"} />
                    <label htmlFor="entertainment_arts">School of Entertainment Arts</label>
                    <input id="fine_arts" type="radio" name="school" value="school of fine arts" onChange={handleSchool} checked={school === "school of fine arts"} />
                    <label htmlFor="fine_arts">School of Fine Arts</label>
                    <input id="liberal_arts" type="radio" name="school" value="school of liberal arts" onChange={handleSchool} checked={school === "school of liberal arts"} />
                    <label htmlFor="liberal_arts">School of Liberal Arts</label>
                </div>
            </form>
        </section>
    )
}

export default Filter