import React, { useState, useEffect } from "react"
import Filter from "./Filter"
import Info from "./Info"
import List from "./List"
import json from "../data.json";
import { motion, AnimatePresence } from "framer-motion"
import { ReactComponent as ToggleIcon } from "../assets/toggle-icon.svg"

function MainPage() {
    const [data, setData] = useState(json)
    const [toggle, setToggle] = useState('majors')
    const [filter, setFilter] = useState(false)
    const [expanded, setExpanded] = useState()
    const [location, setLocation] = useState('all')
    const [school, setSchool] = useState('all schools')

    useEffect(() => handleFilter(), [location, school])

    const handleFilter = () => {
        if (location === "all" && school === "all schools") {
            setExpanded('')
            setData(json)
        } else {
            let newData = json

            if (location !== "all") {
                const filterLocation = list => {
                    return list.filter(item => {
                        let hasLocation = false
                        item.sessions.map(item => {
                            if (item.location.toLowerCase() === location) {
                                hasLocation = true
                            }
                        })
                        return hasLocation
                    })
                }

                newData = {
                    majors: filterLocation(newData.majors),
                    minors: filterLocation(newData.minors)
                }
            }

            if (school !== "all schools") {
                const filterSchool = list => {
                    return list.filter(item => {
                        let name = item.school.toLowerCase()
                        return (name === "all schools" || name === school) ? true : false
                    })
                }
                newData = {
                    majors: filterSchool(newData.majors),
                    minors: filterSchool(newData.minors)
                }
            }

            setExpanded('')
            setData(newData)
        }
    }

    return (
        <main className="mainPage">
            <div className="mainPage__sidebar">
                <div className="mainPage__container--sidebar-top">
                    <h1 className="mainPage__header">
                        <span className="mainPage__header--scad">SCAD</span>
                        <span className="mainPage__header--event">MAJORS + MINORS FAIR</span>
                    </h1>
                    <Filter
                        location={location}
                        setLocation={setLocation}
                        school={school}
                        setSchool={setSchool}
                    />
                </div>
                <Info />
            </div>
            <div className="mainPage__body">
                <div className="mainPage__container--mobile-header">
                    <h1 className="mainPage__header">
                        <span className="mainPage__header--scad">SCAD</span>
                        <span className="mainPage__header--event">MAJORS + MINORS FAIR</span>
                    </h1>
                </div>
                <div className="mainPage__container--mobile">
                    <nav className="mainPage__toggle">
                        <button
                            onClick={() => setToggle('majors')}
                            className={`mainPage__toggle-btn ${toggle === 'majors' ? 'mainPage__toggle-btn--selected' : ''}`}
                        >
                            Majors
                        </button>
                        <button
                            onClick={() => setToggle('minors')}
                            className={`mainPage__toggle-btn ${toggle === 'minors' ? 'mainPage__toggle-btn--selected' : ''}`}
                        >
                            Minors
                        </button>
                        <motion.div
                            className="mainPage__toggle-bg"
                            animate={{ transform: toggle === 'majors' ? "translateX(0%)" : "translateX(100%)" }}
                        >
                        </motion.div>
                    </nav>
                    <button
                        className={`mainPage__filter-btn ${filter ? 'mainPage__filter-btn--active' : ''}`}
                        onClick={() => setFilter(!filter)}
                    >
                        Filter <ToggleIcon />
                    </button>
                </div>
                <div className={filter ? 'mainPage__container--filter-toggle' : ''}>
                    <AnimatePresence>
                        {filter && (
                            <motion.div
                                className="mainPage__filter-toggle"
                                initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                transition={{ ease: "easeOut", duration: .4 }}
                                variants={{
                                    open: { opacity: 1, height: "auto" },
                                    collapsed: { opacity: 0, height: 0 }
                                }}
                            >
                                <div className="mainPage__filter-toggle--wrapper">
                                    <Filter
                                        location={location}
                                        setLocation={setLocation}
                                        school={school}
                                        setSchool={setSchool}
                                    />
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
                <div className={`mainPage__container--major-minor ${filter ? 'mainPage__filter-toggle--hide' : 'mainPage__filter-toggle--show'}`}>
                    <div className={toggle === 'majors' ? '' : 'mainPage__mobile-hidden'}>
                        <List list={data.majors} title="Majors" expanded={expanded} setExpanded={setExpanded} location={location} />
                    </div>
                    <div className={`mainPage__container--minor ${toggle === 'minors' ? '' : 'mainPage__mobile-hidden'}`}>
                        <List list={data.minors} title="Minors" expanded={expanded} setExpanded={setExpanded} location={location} />
                    </div>
                </div>
            </div>
            <div className="mainPage__container--mobile-bottom-bar">
                {filter
                    ? (
                        <AnimatePresence>
                            <motion.div
                                className="mainPage__container--mobile-bottom-bar-filter-done"
                                initial="hide"
                                animate="show"
                                exit="hide"
                                variants={{
                                    show: { opacity: 1 },
                                    hide: { opacity: 0 }
                                }}
                            >
                                <button className="mainPage__filter-btn--done" onClick={() => setFilter(false)}>Done</button>
                            </motion.div>
                        </AnimatePresence>
                    )
                    : (
                        <Info />
                    )
                }
            </div>
        </main>
    )
}

export default MainPage