import React from 'react'

function EnterBTN({ setShowSplash }) {
    return (
        <button
            className="splash__enter-btn"
            onClick={() => setShowSplash(false)}
        >
            Enter
        </button>
    )
}

function Splash({ setShowSplash }) {
    return (
        <>
            <div className="splash">
                <div className="splash__container">
                    <div className="splash__container--content">
                        <div className="splash__container--mobile">
                            <div className="splash__container--mobile-top-section">
                                <p className="splash__date">Friday, Oct. 2, 2020</p>
                                <h1 className="splash__header">
                                    <span className="splash__header--scad">SCAD</span>
                                    <span className="splash__header--event">MAJORS + MINORS FAIR</span>
                                </h1>
                                <div className="splash__hr"></div>
                                <p className="splash__desc">Explore all the majors and minors at SCAD and find your perfect fit.</p>
                                <ul className="splash__list">
                                    <li><span>Connect with SCAD faculty about the degrees that interest you.</span></li>
                                    <li><span>Find out how a minor can fit into your degree requirements.</span></li>
                                    <li><span>Browse each major’s courses.</span></li>
                                    <li><span>Chat with student success advisers.</span></li>
                                </ul>
                                <ul className="splash__time">
                                    <li>SCAD Savannah 9–11:45 a.m.</li>
                                    <li>SCAD Atlanta 1–3:45 p.m.</li>
                                </ul>
                            </div>
                            <div className="splash__mobile-bg--top"></div>
                        </div>
                        <div className="splash__container--mobile-bottom-section">
                            <EnterBTN setShowSplash={setShowSplash} />
                            {/* <div className="splash__coming-soon">coming soon</div> */}
                            <div className="splash__mobile-bg">
                                <div className="splash__mobile-bg--left"></div>
                                <div className="splash__mobile-bg--right"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="splash__container--desktop-bg">
                <div className="splash__desktop-bg">
                    <div className="splash__desktop-bg--top-left"></div>
                    <div className="splash__desktop-bg--top-mid"></div>
                    <div className="splash__desktop-bg--top-right"></div>
                    <div className="splash__desktop-bg--bottom-left"></div>
                    <div className="splash__desktop-bg--bottom-mid"></div>
                    <div className="splash__desktop-bg--bottom-right">
                        <EnterBTN setShowSplash={setShowSplash} />
                        {/* <div className="splash__coming-soon">coming soon</div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Splash