import React from "react"
import Accordion from "./Accordion";
import { ReactComponent as ArrowIcon } from "../assets/arrow.svg"
import { motion, AnimatePresence } from "framer-motion"
import { gtmButtonPushEvent } from "../gtm"

function List({ list, title, expanded, setExpanded, location }) {
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)
    return (
        <>
            <div className={`list__container--header ${title === "Minors" ? 'list__container--minor' : ''}`}>
                <h2 className="list__header">{title}</h2>
                <hr className="list__border" />
            </div>
            <AnimatePresence>
                {list.map((item, i) => (
                    <motion.div
                        key={`accordion-major-${i}`}
                        initial="hide"
                        animate="show"
                        exit="hide"
                        variants={{
                            show: { opacity: 1 },
                            hide: { opacity: 0 }
                        }}
                    >
                        <Accordion
                            i={i}
                            id={`${title}-${i}`}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            title={item.name}
                        >
                            <h3 className="list__school"><em>{item.school}</em></h3>
                            <ul className="list">
                                {item.sessions.map((session, k) => {
                                    if (location === "all" || location.toLowerCase() === session.location.toLowerCase()) {
                                        return (
                                            <li
                                                className={session.location === 'Savannah' ? 'list--sav' : 'list--atl'}
                                                key={`list-major-${i}-${k}`}
                                            >
                                                <a className="list__join" href={session.zoomUrl} target="_blank" rel="noopener noreferrer" onClick={() => handleClick(session.zoomUrl)}>
                                                    <div>
                                                        <span>{`${session.location} - ${session.time}`}</span>
                                                        <span> session</span>
                                                    </div>
                                                    <span className="list__join-btn">Join <ArrowIcon /></span>
                                                </a>
                                            </li>
                                        )
                                    }
                                })}
                            </ul>
                        </Accordion>
                    </motion.div>
                ))}
            </AnimatePresence>
        </>
    )
}

export default List