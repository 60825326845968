import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { ReactComponent as PlusIcon } from "../assets/plus-icon.svg"

function Accordion({ i, id, expanded, setExpanded, title, children }) {
    const isOpen = id === expanded;
    const handleClick = () => setExpanded(isOpen ? false : id)

    return (
        <div className="accordion">
            { i !== 0 && <hr className="accordion__border" />}
            <h3 className="accordion__header">
                <motion.button
                    aria-expanded={isOpen}
                    initial={false}
                    animate={{ backgroundColor: isOpen ? "rgba(238,238,238,1)" : "rgba(238,238,238,0)" }}
                    className="accordion__btn"
                    onClick={handleClick}
                >
                    {title}
                    <PlusIcon
                        className={`accordion__icon ${isOpen ? "accordion__icon--close" : "accordion__icon--open"}`}
                    />
                </motion.button>
            </h3>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        className="accordion__body"
                        hidden={!isOpen}
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                    >
                        <div className="accordion__container--body">
                            {children}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}
export default Accordion