import React, { useState } from 'react'
import Splash from "./components/Splash"
import MainPage from "./components/MainPage"
import "./styles/main.scss"

function App() {
    const [showSplash, setShowSplash] = useState(true)

    return showSplash ? <Splash setShowSplash={setShowSplash} /> : <MainPage />
}

// function App() {
//     return <Splash />
// }

export default App;
