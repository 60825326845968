import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { gtmButtonPushEvent } from "../gtm"

function Panel({ open, close, children }) {
    return (
        <AnimatePresence initial={false}>
            {open && (
                <motion.div
                    hidden={!open}
                    initial="collapsed"
                    className="info__panel"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                    }}
                >
                    <div className="info__panel-bg"></div>
                    <div className="info__container--panel">
                        <button className="info__close-btn" onClick={close}>&#10005;</button>
                        {children}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

function Info() {
    const [req, setReq] = useState(false)
    const [chat, setChat] = useState(false)
    const handleClick = link => gtmButtonPushEvent("Link Click", "Outgoing", link)

    const toggleReq = () => {
        if (req) {
            setReq(false)
        } else {
            setChat(false)
            setReq(true)
        }
    }
    const toggleChat = () => {
        if (chat) {
            setChat(false)
        } else {
            setReq(false)
            setChat(true)
        }
    }

    return (
        <section className="info">
            <Panel open={req} close={() => setReq(false)}>
                <h2>Degree requirements</h2>
                <p>Review the degree requirements to help you understand the credit hours and time required to earn a degree. Follow the link below and find the program you’re interested in to learn more.</p>
                <a href="https://depts.scad.edu/student-success-and-advising/degree-requirements-programs-of-study" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://depts.scad.edu/student-success-and-advising/degree-requirements-programs-of-study')}>VIEW DEGREE REQUIREMENTS</a>
            </Panel>
            <Panel open={chat} close={() => setChat(false)}>
                <h2>Need help? Chat with student success advisers.</h2>
                <p>Student success advisers are standing by to answer any questions you may have.</p>
                <a href="https://scad.zoom.us/j/97284260676" target="_blank" rel="noopener noreferrer" onClick={() => handleClick('https://scad.zoom.us/j/97284260676')}>BEGIN A ZOOM/CHAT SESSION</a>
            </Panel>
            <div className="info__container">
                <button className="info__req-btn" onClick={toggleReq}>Degree Requirements</button>
                <button className="info__chat-btn" onClick={toggleChat}>Chat</button>
            </div>
        </section>
    )
}

export default Info