export const gtmPush = function (pushData) {
    // eslint-disable-next-line no-undef
    dataLayer.push(pushData);
}


export const gtmPushPageVisit = function (pageUrl, pageTitle) {

    let pushData = {
        'event': 'virtualVisit',
        'virtualPageURL': pageUrl,
        'virtualPageTitle': pageTitle
    }

    gtmPush(pushData);
}


export const gtmPushEvent = function (evType, evCat, evAct, evLab, evVal) {

    if (evVal == undefined) {
        evVal = 0.00;
    }

    let pushData = {
        'event': evType,
        'eventCategory': evCat,
        'eventAction': evAct,
        'eventLabel': evLab,
        'eventValue': evVal,
    }

    gtmPush(pushData);
}


export const gtmButtonPushEvent = function (evCat, evAct, evLab, evVal) {

    if (evVal == undefined) {
        evVal = 0.00;
    }

    let pushData = {
        'event': "custom_push",
        'eventCategory': evCat,
        'eventAction': evAct,
        'eventLabel': evLab,
        'eventValue': evVal,
    }

    gtmPush(pushData);
}